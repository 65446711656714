.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-logo {
  height: 100px;
}

main {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.hero {
  margin-bottom: 40px;
}

.cta-button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.features, .benefits, .testimonials {
  margin-bottom: 40px;
}

ul {
  text-align: left;
}

blockquote {
  font-style: italic;
  border-left: 4px solid #61dafb;
  padding-left: 20px;
  margin-left: 0;
}

.App-footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}